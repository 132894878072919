import React from "react";
import { useTheme } from "@mui/material/styles";

type DeleteIconProps = {
  width?: number;
  height?: number;
  onClick?: () => void;
};

const DeleteIcon: React.FC<DeleteIconProps> = ({
  width = 16,
  height = 16,
  onClick = () => {},
}) => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 16 16`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M13.6666 4H2.33325"
        stroke={theme.palette.error.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.5556 5.66669L12.2489 10.2661C12.1309 12.036 12.0719 12.921 11.4953 13.4605C10.9186 14 10.0317 14 8.2578 14H7.74223C5.96836 14 5.08142 14 4.50475 13.4605C3.92808 12.921 3.86908 12.036 3.75109 10.2661L3.44446 5.66669"
        stroke={theme.palette.error.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.11389 2.66665C6.38845 1.88985 7.12928 1.33331 8.00009 1.33331C8.8709 1.33331 9.61173 1.88985 9.88628 2.66665"
        stroke={theme.palette.error.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DeleteIcon;
